<template>
  <!-- 礼物管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" rowKey="id">
            <!-- logo插槽 -->
            <span slot="logo" slot-scope="text, record" @click="clickSwitch(record)">
              <img :src="text" alt="" />
            </span>
            <!-- 礼物状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {agentPageList} from "@/api/modular/mallLiving/agent";

const columns = [
  {
    title: '姓名',
    align: 'center',
    dataIndex: 'realName',
    key: 'realName',
  },
  {
    title: 'ID号',
    dataIndex: 'number',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'idNo',
    align: 'center',
    key: 'idNo',
  },
  {
    title: '绑定商家',
    align: 'center',
    dataIndex: 'storeName',
    key: 'storeName',
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      giftStatusList: [
        { status: 1, name: '启用' },
        { status: 3, name: '禁用' },
      ],
      areaId:''
    }
  },
  components: {
  },
  mounted() {
    this.areaId = this.$route.query.areaId
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    clickSwitch(record) {
      this.recordItem = record
    },

    changeSwitch(e) {
      let delFlag = e ? 1 : 3
      setTimeout(() => {
        this.changeTypeStatus(delFlag)
      }, 300)
    },
    giftDelete(record) {
      this.recordItem = record
      this.changeTypeStatus(2)
    },
    //切换礼物状态函数（启用/禁用/删除）
    async changeTypeStatus(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await area(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success('礼物 ' + this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: {...this.queryParam,areaId:this.areaId,result:2},
        page: this.page,
      }
      agentPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 40px;
  height: 40px;
}
</style>
